<template>
  <div class="Room bg-white">
    <Row :gutter="8" class="px-1">
      <i-col span="24" class="align-justify">
        <!-- <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addRole"></Button>-->
        <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>正在加载...</div>
        </Spin>
        <RadioGroup v-model="border" @on-change="checkRole">
          <Tooltip
            max-width="200"
            placement="bottom-start"
            v-for="item in roleList"
            :key="item.value"
            :content="item.describe"
          >
            <Radio border :label="item.value">{{item.label}}</Radio>
          </Tooltip>
        </RadioGroup>
        <Tree ref="tree" :data="siderMenu" show-checkbox check-directly></Tree>
        <Button type="primary" :disabled="RoleId == 1" v-if="RoleId == 1">超级管理员身份权限配置不允许修改</Button>
        <Button type="primary" v-else @click="setRole">修改</Button>
      </i-col>
    </Row>
  </div>
</template>
<script>
export default {
  name: "Role",
  data() {
    return {
      spinShow: true,
      border: 1,
      roleName: "",
      siderMenu: [],
      RoleId: "1",
      roleList: [],
    };
  },
  mounted() {
    this.$get("Role/GetRoleList", {
      requestName: "GetRoleList",
    }).then((res) => {
      this.roleList = res.data.responseList;
      this.$get("Role/GetMenuByRole", { roleId: this.RoleId }).then((res) => {
        this.siderMenu = this.extendData(res.data.responseList);
        this.spinShow = false;
      });
    });
  },
  methods: {
    checkRole(row) {
      this.RoleId = row;
      this.spinShow = true;
      this.$get("Role/GetMenuByRole", { roleId: row }).then((res) => {
        this.siderMenu = this.extendData(res.data.responseList);
        this.spinShow = false;
      });
    },
    extendData(dataArr) {
      dataArr.forEach((item, index) => {
        item.title = item.title;
        if (item.checked) {
          if (item.children && item.children.length > 0) {
            this.$set(item, "checked", false);
          } else {
            this.$set(item, "checked", true);
          }
        }
        if (item.children && item.children.length > 0) {
          this.extendData.call(this, item.children);
        }
      });
      return dataArr;
    },
    setRole() {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认修改权限配置?</p>",
        loading: true,
        onOk: () => {
          let nodesetting = [];
          let checkedStr = "";
          let minateNode = "";
          nodesetting = this.$refs.tree.getCheckedAndIndeterminateNodes();
          for (let i = 0; i < nodesetting.length; i++) {
            checkedStr += nodesetting[i].menuId + ",";
          }
          this.$post("Role/SetMenuByRole", {
            roleId: this.RoleId,
            menu: checkedStr,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {
          this.$Message.error("取消修改!");
        },
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
</style>
